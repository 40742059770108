<template>
  <div>
    <v-card flat>
      <v-card-title>
        <label class="primary--text px-5 font-weight-bold info-page-title ma-auto">{{ $t(`label.faq`) }}</label>
      </v-card-title>
    </v-card>
    <v-card class="info-page-card">
      <v-expansion-panels accordion v-model="panel">
        <v-expansion-panel v-for="(info, i) in faqContent" :key="i">
          <v-expansion-panel-header class="font-weight-bold text-uppercase">{{ info.title }}</v-expansion-panel-header>
          <v-expansion-panel-content class="py-4 info-expension-panel-content">
            <div v-for="content in info.content" :key="content.title">
              <p class="text-capitalize font-weight-bold info-expension-panel-content-title mb-1" v-html="content.title"></p>
              <p v-html="content.desc"></p>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>
<script>
import { locale } from '@/util'
export default {
  name: 'faq',
  data: () => ({
    panel: 0,
    faqContent: [
      {
        title: locale.getMessage(`info.info_faq`),
        content: [
          {
            title: locale.getMessage(`info.info_faq_a_title`),
            desc: locale.getMessage(`info.info_faq_a_desc`)
          },
          {
            title: locale.getMessage(`info.info_faq_b_title`),
            desc: locale.getMessage(`info.info_faq_b_desc`)
          },
          {
            title: locale.getMessage(`info.info_faq_c_title`),
            desc: locale.getMessage(`info.info_faq_c_desc`)
          },
          {
            title: locale.getMessage(`info.info_faq_d_title`),
            desc: locale.getMessage(`info.info_faq_d_desc`)
          },
          {
            title: locale.getMessage(`info.info_faq_e_title`),
            desc: locale.getMessage(`info.info_faq_e_desc`)
          },
          {
            title: locale.getMessage(`info.info_faq_f_title`),
            desc: locale.getMessage(`info.info_faq_f_desc`)
          },
          {
            title: locale.getMessage(`info.info_faq_g_title`),
            desc: locale.getMessage(`info.info_faq_g_desc`)
          }
        ]
      }
    ]
  })
}
</script>
